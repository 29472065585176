<template>
  <section class="add-faq">
    <!-- <hr class="my-5" /> -->
    <label>Nombre de pregunta</label>
    <p class="mb-4">
      Define con un nombre esta configuración para que puedas identificarla
      fácilmente. Este nombre es de caracter interno / administrativo para ti y
      tu equipo.
    </p>
    <div class="row">
      <!-- :class="{
            input_error: error ? 'empty_name_faq' : 'name_alredy_exist',
            'mb-2': error == 'empty_name_faq',
          }" -->
      <div class="col-lg-12 px-0">
        <input
          class="mb-0"
          :class="dinamycInput_error"
          type="text"
          maxlength="54"
          placeholder="Ejm: Nuestro producto"
          @input="$emit('liveNameMethod', faq.intent)"
          v-model="faq.intent"
        />
        <span v-if="error == 'empty_name_faq'" class="text_error"
          >Completa este campo</span
        >
        <span v-if="error == 'name_alredy_exist'" class="text_error"
          >Este nombre ya existe</span
        >
      </div>
      <!-- <div class="col-lg-1 px-0 justify-content-end align-items-center d-flex">
        <div class="buefy">
          <b-switch v-model="faq.active" />
        </div>
      </div> -->
    </div>
    <p class="info mt-3 mb-5">
      {{ faq.active ? 'Pregunta activa' : 'Pregunta desactivada' }}
    </p>
    <div class="respuesta">
      <div class="row">
        <div class="col-lg-11 px-0">
          <label class="mr-2">Preguntas</label>
          <div class="tooltip-custom fas fa-question-circle">
            <p class="tooltip_text">
              Los clientes pueden referirse a un concepto de distintas maneras.
              Define todas las formas posibles que tiene para preguntar lo que
              necesitan, incluyendo sinónimos o palabras clave.
            </p>
          </div>
          <p class="mb-4">
            Edita las preguntas o palabras clave que activarán esta respuesta
            automática del chatbot. Es recomendable tener al menos 3 de ellas.
          </p>
        </div>
      </div>
      <!-- v-for -->
      <div
        v-for="(trainingPhrase, trainingPhraseIndex) in trainingPhrases"
        :key="trainingPhrase.id"
        class="row"
      >
        <div class="delete-input w-100">
          <input
            type="text"
            placeholder="Ejm: ¿Qué es un chatbot?"
            v-model="trainingPhrase.text"
          />
          <button
            :disabled="trainingPhrases.length == 1"
            @click="deletePhrase(trainingPhraseIndex)"
          >
            <i class="fas fa-trash-alt" />
          </button>
        </div>
        <!-- <div class="col-lg-4 pr-0">
          <div class="d-flex">
            <select v-model="trainingPhrase.match_type">
              <option value="">Seleccionar</option>
              <option value="ia">Inteligente</option>
            </select>
          </div>
        </div> -->
      </div>
      <button class="btn btn-dash btn-dash-sm m-0" @click="addTrainingPhrase">
        +
      </button>
    </div>
    <div>
      <div
        v-for="(response, responseIndex) in faq.watson_responses"
        :key="responseIndex"
        class="answer_box"
      >
        <hr class="my-5" />
        <div class="row justify-content-between align-items-center">
          <label
            >Tipo de respuesta del chatbot Nº {{ responseIndex + 1 }}</label
          >
          <p
            v-if="faq.watson_responses && faq.watson_responses.length > 1"
            class="btn-text text_sm text-danger"
            @click="deleteWatsonResponse(faq.watson_responses, responseIndex)"
          >
            <i class="fas fa-times" />Eliminar respuesta {{ responseIndex + 1 }}
          </p>
        </div>
        <div class="col-lg-8 px-0">
          <div class="row justify-content-between my-3">
            <div
              v-for="(type, typeIndex) in typeOptions"
              :key="typeIndex"
              class="d-flex align-items-center"
            >
              <input
                type="radio"
                v-model="response.response_type"
                :value="type.value"
                :id="'radio' + typeIndex"
                @change="
                  changeType(faq.watson_responses, responseIndex, type.value)
                "
              />
              <label :for="'radio' + typeIndex" class="label-radio ml-2 mb-0"
                >{{ type.label }}
              </label>
            </div>
          </div>
        </div>
        <br />
        <!-- Texto -->
        <div class="mb-4" v-if="response.response_type == 'text'">
          <label>Mensaje</label>
          <textarea
            class="no_textarea mt-0"
            :class="error == 'empty_text' ? 'input_error' : ''"
            placeholder="Ejm: Te invitamos a revisar en el siguiente link: www.link.cl"
            cols="30"
            rows="4"
            v-model="response.text"
          />
          <span v-if="error == 'empty_text'" class="text_error"
            >Completa este campo</span
          >
        </div>
        <!-- boton -->
        <div v-if="response.response_type == 'option'">
          <label>Texto antes del botón</label>
          <div class="row mb-4">
            <div class="col-lg-7 px-0">
              <input
                type="text"
                placeholder="Ejm: Selecciona un botón"
                v-model="response.title"
                required
              />
            </div>
          </div>
          <div
            class="buttons_box"
            v-for="(button, buttonIndex) in response.options"
            :key="buttonIndex"
          >
            <div class="row">
              <div class="col-lg-7 px-0">
                <div class="row justify-content-between align-items-center">
                  <label>{{ buttonIndex + 1 }}. Nombre del botón</label>
                  <p class="text_xs mb-2">{{ button.label.length }} / 20</p>
                </div>
                <input
                  type="text"
                  maxlength="20"
                  placeholder="Texto del botón: Ejm click aquí"
                  v-model="button.label"
                  required
                />
                <!-- <p class="text_error" v-if="error == 'invalid_button'">Debes completar este campo</p> -->
              </div>
              <div class="col-lg-5 pr-0">
                <label>Acción</label>
                <select
                  v-model="button.type"
                  @change="changeOptionTemplate(button)"
                >
                  <option value="">Seleccionar</option>
                  <option value="url">Url</option>
                  <option value="text">Texto</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 px-0">
              <textarea
                v-if="
                  button.type === 'text' && button.responsesOnClick.length > 0
                "
                class="no_textarea mb-2"
                placeholder="Este texto se activará después del click al botón"
                cols="30"
                rows="4"
                v-model="button.responsesOnClick[0].text"
              />
              <input
                v-else
                type="text"
                placeholder="https://www..."
                v-model="button.value.input.text"
              />
            </div>
            <button
              v-for="(button, buttonIndex) in buttonModel"
              :key="buttonIndex"
              v-show="button.label && button.label.length >= 1"
              class="btn btn-base disabled mr-3 mt-2 mb-1"
            >
              {{ button.label }}
              <i
                class="fas fa-times"
                @click="deleteOptionButtons(buttonIndex)"
              ></i>
            </button>
            <div class="d-flex justify-content-between">
              <p
                @click="deleteOptionButtons(response.options, buttonIndex)"
                class="error_hover mb-0"
              >
                <i class="fas fa-trash-alt" />Eliminar
              </p>
            </div>
          </div>
          <button
            v-if="response.options.length <= 2"
            class="btn btn-dash btn-dash-sm m-0 my-4"
            @click="addOptionSelect(response.options)"
          >
            +
          </button>
          <div class="info_box mb-4">
            <p>
              <i class="fas fa-info-circle" /> Máximo 3 botones. Si necesitas
              más opciones, utiliza el tipo de respuesta "desplegable".
            </p>
          </div>
        </div>
        <!-- select -->
        <div v-if="response.response_type == 'select'">
          <label>Texto antes del desplegable</label>
          <div class="col-lg-7 mb-4 px-0">
            <input
              type="text"
              placeholder="Ejm: Selecciona opciones"
              v-model="response.title"
              required
            />
          </div>
          <div class="row align-items-center justify-content-between">
            <label>Opciones del desplegable</label>
            <span class="text_sm mb-2"
              >{{ response.options.length + ' / 8 Opciones.' }}
            </span>
          </div>
          <div
            v-for="(option, optionIndex) in response.options"
            :key="optionIndex"
            class="select_box"
            :class="{ no_card: !option.viewOption }"
          >
            <div class="row">
              <div class="col-lg-7 px-0">
                <label v-show="option.viewOption"
                  >{{ optionIndex + 1 }}. Nombre de opción</label
                >

                <div class="d-flex">
                  <p class="circle_number" v-show="!option.viewOption">
                    {{ optionIndex + 1 }}.
                  </p>
                  <input
                    type="text"
                    :placeholder="`Texto opción ${optionIndex + 1}`"
                    :class="error == 'empty_option' ? 'input_error' : ''"
                    v-model="option.label"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-5 pr-0">
                <label v-show="option.viewOption">Acción</label>
                <select
                  v-model="option.type"
                  @change="changeOptionTemplate(option)"
                >
                  <option value disabled>Seleccionar</option>
                  <option value="url">Url</option>
                  <option value="text">Texto</option>
                </select>
              </div>
            </div>
            <template
              v-if="option.type == 'text' && option.responsesOnClick.length > 0"
            >
              <textarea
                v-show="option.viewOption"
                class="no_textarea mb-0"
                :class="error == 'invalid_text' ? 'input_error' : ''"
                placeholder="Ejm: Te invitamos a revisar en el siguiente link: www.link.cl"
                cols="30"
                rows="4"
                v-model="option.responsesOnClick[0].text"
              />
              <span class="text_error" v-if="error == 'invalid_text'"
                >Agrega un texto válido
              </span>
            </template>
            <template v-else>
              <input
                type="text"
                :class="error == 'invalid_url' ? 'input_error mb-2' : ''"
                v-show="option.viewOption"
                placeholder="https://www..."
                v-model="option.value.input.text"
              />
              <span class="text_error" v-if="error == 'invalid_url'"
                >Agrega una url válida
              </span>
            </template>
            <div class="d-flex justify-content-between">
              <p
                class="btn-text mb-0"
                :class="{ space: !option.viewOption }"
                @click="dropdownOption(option)"
              >
                {{ !option.viewOption ? 'Mostrar' : 'Ocultar' }}
              </p>
              <p
                v-if="response.options.length > 4"
                @click="deleteOptionSelect(response.options, optionIndex)"
                class="btn-text mb-0"
                :class="{ space: !option.viewOption }"
              >
                <i class="fas fa-trash-alt" />Eliminar
              </p>
            </div>
          </div>
          <button
            v-if="response.options.length < 8"
            class="btn-dash my-4"
            @click="addOptionSelect(response.options)"
          >
            +
          </button>
          <div v-else class="info_box my-5">
            <i class="fas fa-info-circle" />
            <span>Máximo de opciones para el desplegable.</span>
          </div>
        </div>
        <div class="row justify-content-between my-5">
          <button
            class="btn btn-base"
            @click="
              save(response.response_type, response.options, response.text)
            "
            :disabled="loading || view_permissions.edit != true"
          >
            Guardar pregunta
            <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
          </button>
          <!-- <button
            class="btn btn-base outline"
            @click="$emit('cancel')"
            >Limpiar
          </button> -->
        </div>
      </div>
      <template v-if="view_permissions.add == true">
        <hr class="my-5" />
        <p class="btn-text" @click="addWatsonResponse(faq.watson_responses)">
          + Agregar otra respuesta
        </p>
      </template>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import environment from '@/environment';
import dashboard_api from '@/dashboard_api';
import {
  removeEmojis,
  removeAccents,
  removeDuplicateObjects,
} from '@/utils/utils';

export default {
  props: {
    botId: {
      type: Number,
    },
    currentFaq: {
      type: Object,
    },
    businessType: {
      type: Object,
    },
    parent: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
    },
    isSkillBase: {
      type: Boolean,
      default: false,
    },
    isBotLLM: {
      type: Boolean,
      default: false,
    },
    view_permissions: {
      type: Object
    }
    // predefinida: {
    //   type: Boolean,
    // }
  },
  data() {
    return {
      error: '',
      loading: false,
      // SelectedOptionBox: true,
      // selectedBusiness: null,
      // tipo de negocio
      // businessTypes: [],
      // my_register: {},
      // business_types_id: null,
      // Opciones tipo de respuesta
      selectedType: 'text',
      previousIntentName: null,
      faq: {},
      newFaq: {
        intent: '',
        disabled: false,
        watson_responses: [
          {
            text: '',
            response_type: 'text',
          },
        ],
        is_custom: true,
        training_phrases: [
          {
            text: '',
            match_type: 'ia',
          },
        ],
      },
      typeOptions: [
        {
          label: 'Texto',
          value: 'text',
        },
        {
          label: 'Botón',
          value: 'option',
        },
        {
          label: 'Desplegable',
          value: 'select',
        },
      ],
      // Respuesta
      answerName: '',
      trainingPhrases: [
        {
          text: '',
          match_type: 'ia',
        },
        // {
        //   text: '',
        //   match_type: 'ia',
        // },
        // {
        //   text: '',
        //   match_type: 'ia',
        // },
      ],
      // text
      textModel: {
        text: '',
        response_type: 'text',
      },

      // este es el modelo para englobar opciones (botones y select)
      optionsModel: {
        title: '',
        options: [], // aqui se agregan las opciones (buttonModel o selectModel)
        response_type: 'option',
      },

      // button
      buttonTitle: '',
      buttonModel: {
        label: '',
        value: {
          input: {
            text: '', // esta propiedad se calcula mas adelante en base al label
          },
        },
        responsesOnClick: [
          {
            text: '',
            response_type: 'text',
          },
        ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
        type: 'url', // puede tomar valores url || text
      },
      // select
      selectTitle: '',
      selectModel: [
        {
          label: '',
          value: {
            input: {
              text: '', // esta propiedad se calcula mas adelante en base al label
            },
          },
          responsesOnClick: [
            {
              text: '',
              response_type: 'text',
            },
          ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
          type: 'text', // puede tomar valores url || text
          viewOption: true,
        },
        {
          label: '',
          value: {
            input: {
              text: '', // esta propiedad se calcula mas adelante en base al label
            },
          },
          responsesOnClick: [
            {
              text: '',
              response_type: 'text',
            },
          ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
          type: 'url', // puede tomar valores url || text
          viewOption: false,
        },
        {
          label: '',
          value: {
            input: {
              text: '', // esta propiedad se calcula mas adelante en base al label
            },
          },
          responsesOnClick: [
            {
              text: '',
              response_type: 'text',
            },
          ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
          type: 'url', // puede tomar valores url || text
          viewOption: false,
        },
        {
          label: '',
          value: {
            input: {
              text: '', // esta propiedad se calcula mas adelante en base al label
            },
          },
          responsesOnClick: [
            {
              text: '',
              response_type: 'text',
            },
          ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
          type: 'url', // puede tomar valores url || text
          viewOption: false,
        },
      ],
    };
  },
  created() {
    window.scrollTo(0, 0);
  },

  computed: {
    ...mapState([
      'user',
      'plan',
      'registerData',
      'selected_bot',
      'skill_template',
    ]),

    dinamycInput_error() {
      if (this.error == 'empty_name_faq') {
        return 'input_error mb-2';
      }
      if (this.error == 'name_alredy_exist') {
        return 'input_error mb-2';
      }
    },
  },
  watch: {
    currentFaq: {
      immediate: true,
      handler(val) {
        this.initialize();
      },
    },
  },
  methods: {
    ...mapMutations(['setRegisterProcess']),

    initialize() {
      // se verifica si se trata de nuevo faq o actualizar
      if (this.currentFaq) {
        this.faq = JSON.parse(JSON.stringify(this.currentFaq));
      } else {
        this.faq = JSON.parse(JSON.stringify(this.newFaq));
      }
      // this.selectedType=this.
      this.previousIntentName = JSON.parse(JSON.stringify(this.faq.intent));
      this.faq['active'] = !this.faq.disabled;

      this.trainingPhrases = this.faq.training_phrases;
      // reemplazando option por select si corresponde
      for (const response of this.faq.watson_responses) {
        if (
          response.response_type === 'option' &&
          response.options.length > 3
        ) {
          response.response_type = 'select';
        }
      }
    },
    addOptionSelect(options) {
      options.push({
        label: '',
        value: {
          input: {
            text: '', // esta propiedad se calcula mas adelante en base al label
          },
        },
        responsesOnClick: [
          {
            text: '',
            response_type: 'text',
          },
        ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
        type: 'url', // puede tomar valores url || text
        viewOption: false,
      });
    },
    addTrainingPhrase() {
      this.trainingPhrases.push({
        text: '',
        match_type: 'ia',
      });
    },
    addButton() {
      if (this.buttonModel.length <= 2) {
        this.buttonModel.push({
          label: '',
          value: {
            input: {
              text: '', // esta propiedad se calcula mas adelante en base al label
            },
          },
          responsesOnClick: [
            {
              text: '',
              response_type: 'text',
            },
          ], // esta propiedad es por si un boton debe generar alguna respuesta de texto
          type: 'url', // puede tomar valores url || text
        });
      }
    },
    dropdownOption(option) {
      // for (const select_item of this.businessTypes) {
      //   select_item.viewOption = false;
      // }
      if (!option.viewOption) {
        this.$set(option, 'viewOption', !option.viewOption);
      } else {
        option.viewOption = !option.viewOption;
      }
    },
    // selectItem(item) {
    //   this.SelectedOptionBox = item;
    //   for (const select_item of this.businessTypes) {
    //     select_item.show = false;
    //   }
    //   item.show = !item.show;
    // },

    // getBusinessTypes() {
    //   dashboard_api.get("/business_types/all_not_custom").then((response) => {
    //     this.businessTypes = response.data;

    //     this.businessTypes = this.businessTypes.map((businessType) => ({
    //       ...businessType,
    //       show: false,
    //     }));
    //   });
    //   // this.my_register = this.registerData;
    // },
    deletePhrase(index) {
      this.trainingPhrases.splice(index, 1);
    },

    async save(resResponse_type, resResponseOption, resResponseText) {
      console.log(
        'resResponse_type->',
        resResponse_type,
        '\nresResponseOption->',
        resResponseOption,
        '\nresResponseText->',
        resResponseText,
      );
      if (this.faq.intent.trim().length === 0) {
        // Valida que exista nombre de la respuesta
        this.error = 'empty_name_faq';

        this.$swal({
          icon: 'error',
          title: 'Ingresa un nombre a esta respuesta personalizada',
        });
      } else if (
        resResponse_type === 'select' &&
        resResponseOption.some((el) => el.label.length == 0)
      ) {
        // validacion si es select y falta un nombre al option
        this.error = 'empty_option';
      } else if (
        resResponse_type === 'select' &&
        resResponseOption.some(
          (el) => el.type == 'url' && !el.value.input.text.includes('http'),
        )
      ) {
        // validacion si es select option tipo url y no incluye http
        this.error = 'invalid_url';
      } else if (
        resResponse_type === 'select' &&
        resResponseOption.some(
          (el) => el.type == 'text' && el.responsesOnClick[0].text.trim() == 0,
        )
      ) {
        // Validacion si es select tipo text y hay campos vacios en options
        this.error = '';
        this.error = 'invalid_text';
      } else if (resResponse_type === 'text' && resResponseText.length == 0) {
        // Validacion si es texto y hay campo vacio
        this.error = 'empty_text';
      } else {
        // Inicia el guardado de faq
        this.error = '';
        this.loading = true;
        let faqToStore = JSON.parse(JSON.stringify(this.faq));
        // se valida las frases de entrenamiento (no nulos, no repetidos)
        faqToStore.training_phrases = removeDuplicateObjects(
          faqToStore.training_phrases.filter((el) => el.text),
          'text',
        );
        // dando formato a options para watson assistant
        for (const response of faqToStore.watson_responses) {
          if (
            response.response_type === 'option' ||
            response.response_type === 'select'
          ) {
            for (const option of response.options) {
              if (option.type === 'url') {
                option.responsesOnClick = []; // por ser url, no tendrá respuestas al dar click
              } else {
                option.value.input.text = removeAccents(
                  removeEmojis(option.label)
                    .trim()
                    .toLowerCase()
                    .split(' ')
                    .join('_'),
                );
              }
              if (option.hasOwnProperty('viewOption')) {
                delete option['viewOption'];
              }
            }
            if (response.options.length > 3) {
              response.response_type = 'option'; // se cambia de select a option para watson
            }
          }
        }
        // se agrega el id del chatbot
        faqToStore = { ...faqToStore, bot_id: this.botId };
        // eliminando propiedad active
        faqToStore.disabled = !faqToStore['active'];
        delete faqToStore['active'];
        delete faqToStore['bot'];
        // comenzando save
        let hasSkillBase = this.selected_bot.has_skill_base;
        // en skill base, se usa el mismo endpoint para crear y actualizar
        if (this.currentFaq) {
          // actualizar
          try {
            // hay 2 caminos. Bots plan mensual o bots a medida/corporate
            if (hasSkillBase) await this.createUpdateSkillBaseFaq(faqToStore);
            else if (this.businessType.show === true) {
              // actualizando en Watson Assistant
              await axios.put(
                `${environment.socket_url}/assistantV1/intents/${this.botId}`,
                {
                  previousIntentName: this.previousIntentName,
                  intentName: faqToStore.intent,
                  examples: faqToStore.training_phrases,
                },
              );
              // actualizando en bd
              await dashboard_api.put(`/frequent_answers/${this.faq.id}`, {
                frequent_answer: faqToStore,
              });
            } else {
              // actualizar mediante API en Watson Assistant
              await axios.put(
                `${environment.socket_url}/assistantV1/faq/${faqToStore.intentName}`,
                {
                  previousIntentName: this.previousIntentName,
                  skill_id: this.businessType.skill_id,
                  api_key: this.businessType.api_key,
                  faq: faqToStore,
                },
              );
            }
            this.$emit('updatedCustomFaq', {
              faq: faqToStore,
              previousIntentName: this.previousIntentName,
            });
            // actualizando el nombre del intent previo
            this.previousIntentName = faqToStore.intent; // solo se usa para bots plan mensual
            this.loading = false;
            // contraer vista y limpiar datos
            this.$emit('cancel');
            // Mensaje de espera
            this.$swal({
              icon: 'success',
              title: 'Conocimiento agregado',
              text: 'Los cambios se verán reflejados después de presionar "Actualizar chatbot"',
              showConfirmButton: true,
            });
            console.log("updateView 1->");
            this.$emit('updateView');
          } catch (error) {
            console.log('rror->', error);
            this.error = 'name_alredy_exist';
            this.loading = false;
            this.$swal({
              icon: 'error',
              title: 'No se pudo guardar',
              text: 'Ya existe una pregunta personalizada con este nombre.',
            });
          }
        } else {
          // crear
          try {
            let faqCreated;
            let options;
            // creando custom faq para bots con skill base
            if (hasSkillBase === true) {
              faqCreated = await this.createUpdateSkillBaseFaq(faqToStore);
              faqCreated = { ...faqToStore, ...faqCreated };
            } else if (this.businessType.show === true) {
              await axios.post(
                `${environment.socket_url}/assistantV1/intents`,
                {
                  intentName: faqToStore.intent,
                  examples: faqToStore.training_phrases,
                  botId: faqToStore.bot_id,
                },
              );
              // creando en bd
              faqCreated = (
                await dashboard_api.post('/frequent_answers', {
                  frequent_answer: faqToStore,
                })
              ).data;
            } else {
              faqCreated = (
                await axios.post(`${environment.socket_url}/assistantV1/faq`, {
                  parent: this.parent,
                  skill_id: this.businessType.skill_id,
                  faq: faqToStore,
                  api_key: this.businessType.api_key,
                  is_skill_base: this.isSkillBase,
                })
              ).data.payload;
              // se combina la respuesta con el data del componente, para obtener id del nodo creado y nombre intent
              faqCreated = { ...faqToStore, ...faqCreated };
            }
            // agregando estado activo
            this.previousIntentName = faqCreated.intent;
            faqCreated.active = !faqCreated.disabled;
            this.$emit('createdCustomFaq', faqCreated);
            // contraer vista y limpiar datos
            this.$emit('cancel');
            // Mensaje de espera
            this.$swal({
              icon: 'success',
              title: 'Conocimiento agregado',
              text: hasSkillBase === true ? 'Los cambios se verán reflejados después de presionar "Actualizar chatbot"' : '',
              showConfirmButton: true,
            });
            console.log("updateView 2->");
            this.$emit('updateView');
          } catch (error) {
            // console.log(error);
            this.error = 'name_alredy_exist';
            this.loading = false;
            this.$swal({
              icon: 'error',
              title: 'No se pudo guardar',
              text: 'Ya existe una pregunta personalizada con este nombre.',
            });
          }
        }
        // check if is LLM
        if (this.isBotLLM && this.selected_bot.has_skill_base === false) {
          this.$store.dispatch(
            'assistantsModule/generateFromWatson',
            this.businessType.assistant_id,
          );
        }
        if (this.error) {
          return;
        }
        this.error = '';
        this.loading = false;
        this.$emit('cancel');
      }
    },
    deleteOptionButtons(options, optionIndex) {
      options.splice(optionIndex, 1);
    },
    deleteOptionSelect(options, optionIndex) {
      if (options.length > 4) {
        options.splice(optionIndex, 1);
      }
    },
    deleteWatsonResponse(responses, responseIndex) {
      console.log('HERE ------------------>', responses);
      this.$swal({
        title: '¿Eliminar respuesta?',
        text: 'Tu chatbot ya no reconocerá esta respuesta.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('HERE ------------------>', responses);
          if (responses.length > 1) {
            // console.log('responses-1', responses);
            responses.splice(responseIndex, 1);
            // console.log('responses-2', responses);

            this.$swal({
              icon: 'success',
              title: 'Respuesta eliminada',
              timer: 1000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
    addWatsonResponse(responses) {
      if (responses.length < 5) {
        responses.push(JSON.parse(JSON.stringify(this.textModel)));
      }
    },
    changeType(responses, index, newType) {
      switch (newType) {
        case 'text':
          responses.splice(
            index,
            1,
            JSON.parse(JSON.stringify(this.textModel)),
          );
          break;
        case 'option':
          responses.splice(
            index,
            1,
            JSON.parse(JSON.stringify(this.optionsModel)),
          );
          // inicializando con 1 boton
          responses[index].options.push(
            JSON.parse(JSON.stringify(this.buttonModel)),
          );
          break;
        case 'select':
          responses.splice(
            index,
            1,
            JSON.parse(JSON.stringify(this.optionsModel)),
          );
          responses[index].response_type = 'select';
          // inicializando con 4 botones
          responses[index].options.push(
            JSON.parse(JSON.stringify(this.buttonModel)),
          );
          responses[index].options.push(
            JSON.parse(JSON.stringify(this.buttonModel)),
          );
          responses[index].options.push(
            JSON.parse(JSON.stringify(this.buttonModel)),
          );
          responses[index].options.push(
            JSON.parse(JSON.stringify(this.buttonModel)),
          );
          break;

        default:
          break;
      }
    },
    changeOptionTemplate(element, option) {
      const type = JSON.parse(JSON.stringify(element.type));

      if (type === 'url') {
        element.value.input.text = '';
        element.responsesOnClick = [];
      }
      if (type === 'text') {
        element.responsesOnClick = [
          {
            text: '',
            response_type: 'text',
          },
        ];
      }
      element.responsesOnClick = element.responsesOnClick.slice();
    },
    updateActiveSwitch(status) {
      this.faq['active'] = status;
    },
    async createUpdateSkillBaseFaq(faqToStore) {
      let context = {};
      let output = {};
      let generic = [];
      console.log('FAQ TO STORE: ', faqToStore);

      let trainingPhrases = faqToStore.training_phrases.map((o) => {
        let example = o.text;
        return example;
      });
      let saveCustomIntent = await this.$store.dispatch(
        'dtClientIntentsModule/addCustomIntent',
        {
          bot_id: faqToStore.bot_id,
          intent_name: faqToStore.intent,
          examples: trainingPhrases,
          template: this.skill_template.value,
        },
      );
      let customNodePayload = {
        bot_id: faqToStore.bot_id,
        intent_name: faqToStore.intent,
        output: faqToStore.watson_responses,
        id: faqToStore.dialog_node,
        context,
      };
      // in case of update, add intent_examples formatted
      if (this.currentFaq) {
        customNodePayload['intent_examples'] = trainingPhrases.map((el) => ({
          text: el,
        }));
      }
      let saveCustomNode = await this.$store.dispatch(
        'dtClientNodesModule/addCustomNode',
        customNodePayload,
      );
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.add-faq {
  .respuesta {
    .fa-trash-alt {
      margin: 0.75rem 0.75rem 1rem;
      color: #b2b2b2;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        color: #767676;
      }
    }
  }
  select {
    width: 100%;
    box-shadow: none;
    border-radius: 0.5rem;
    border: 1px solid #e8e8e8;
  }

  .btn-base {
    margin: 0;
  }
  .col-lg-4,
  .col-lg-5 {
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  .buttons_box {
    padding: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    margin: auto auto 1rem;

    &:last-child {
      margin-bottom: 2rem;
    }
  }
  .select_box {
    padding: 2rem;
    border-radius: 1rem;
    border: 1px solid #e8e8e8;
    background-color: #fafafa;
    margin: 2rem auto 1rem;
    box-shadow: none;

    textarea {
      background-color: #fff;
    }
    .circle_number {
      margin-top: 0.25rem;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #e8e8e8;
      background-color: #fafafa;
      color: #181818;
      margin-right: 1rem;
    }
  }
  .no_card {
    padding: 1rem 0 0.75rem;
    margin: 1rem auto;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #e8e8e8;
  }
  .no_textarea {
    padding: 1.25rem;
    margin: 1rem auto;
  }
  .space {
    margin: 0 0 1rem !important;
  }
  .info {
    color: #b2b2b2;
  }
  .fa-question-circle {
    color: #181818;
  }
  .answer_box {
    .label-radio {
      color: #767676 !important;
      font-weight: 400;
    }
  }
  .input_error {
    margin: 0 0 auto;
  }
  .text_sm {
    font-weight: 400;
  }
}
</style>
