<template>
  <div class="chatbot_view">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container mt-5">
        <div class="row no-row justify-content-center">
          <div class="col-lg-5 mb-5">
            <div class="card_box">
              <h2 class="title">Crea tu nuevo chatbot</h2>
              <div>
                <div class="label-box">
                  <label>Nombre del chatbot</label>
                  <span class="text_sm"
                    >{{ chatbot.bot_name.length }} / 15</span
                  >
                </div>
                <input
                  alternative
                  type="text"
                  required
                  v-model="chatbot.bot_name"
                  name="bot_name"
                  placeholder="Nuevo nombre"
                  maxlength="15"
                />
                <p v-if="error.name" class="text_error">
                  Ingresa un nombre para el chatbot
                </p>
                <label>Imagen de perfil</label>
                <input
                  type="file"
                  @change="processFile($event)"
                  name="bot_logo_url"
                />
                <br />
                <br />
                <div class="field buefy">
                  <div class="d-flex justify-content-between">
                    <label>¿Deseas recibir correos de este bot?</label>
                    <b-switch v-model="chatbot.notification"></b-switch>
                  </div>
                </div>
                <hr class="my-5" />
                <label class="mb-4">Mostrar chatbot en:</label>
                <div class="check__content">
                  <b-checkbox class="bajada" v-model="chatbot.desktop"
                    >Escritorio
                    <img
                      class="desktop_icon"
                      src="/img/icons/widgetWhatsapp/3.1-desktop.svg"
                      alt="Escritorio"
                    />
                  </b-checkbox>
                  <b-checkbox class="bajada" v-model="chatbot.mobile"
                    >Móvil
                    <img
                      class="mobile_icon"
                      src="/img/icons/widgetWhatsapp/3.2-mobile.svg"
                      alt="movil"
                    />
                  </b-checkbox>
                </div>
                <hr class="my-5" />
                <label>¿Abrir ventana automáticamente en escritorio?</label>
                <select v-model="chatbot.init_conver" placeholder="Seleccionar">
                  <option :value="null">Seleccionar</option>
                  <option :value="true">Si (Recomendado)</option>
                  <option :value="false">No</option>
                </select>
                <label>¿Abrir ventana automáticamente en mobile? </label>
                <select
                  v-model="chatbot.init_conver_mobile"
                  placeholder="Seleccionar"
                >
                  <option :value="null">Seleccionar</option>
                  <option :value="true">Si (Recomendado)</option>
                  <option :value="false">No</option>
                </select>
                <hr class="my-5" />
                <div class="temas">
                  <!-- color pestaña -->
                  <div class="temas__item">
                    <label>Color ventana del chatbot</label>
                    <p>
                      El color primario corresponde al color de la barra
                      superior, la burbuja y el botón enviar, el color
                      secundario corresponde al color de las letras.
                    </p>
                    <div class="color_box">
                      <input
                        type="color"
                        v-model="chatbot.bot_title_bar_bg_color"
                        name="bot_title_bar_bg_color"
                      />
                      <p class="mb-0 mr-3">Primario</p>
                      <input
                        type="color"
                        v-model="chatbot.bot_title_color"
                        name="bot_title_color"
                      />
                      <p class="mb-0 mr-3">Secundario</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-5" />
              <!-- <label>Tipo de negocio</label>
              <select
                v-model="chatbot.business_types_id"
                name="bot_font"
                required
                selected
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="business in business_type"
                  :value="business.id"
                  :key="business.name"
                >
                  {{ business.id }}
                </option>
              </select>
              <p v-if="error.chatbot" class="text_error">
                Debes seleccionar un tipo de negocio
              </p> -->
              <label>Zona horaria</label>
              <select v-model="chatbot.time_zone">
                <option v-for="(zone, index) in isTimeZonesList" :key="index" :value="zone">{{ zone }}</option>
              </select>
              <label>Horario de contacto</label>
              <section class="card-item mb-3">
                <div
                  v-for="(schedule, scheduleIndex) in schedules"
                  :key="scheduleIndex"
                >
                  <transition name="fade">
                    <div v-if="schedule.showValues">
                      <div class="checks_box">
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Lu"
                        >
                          Lu
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Ma"
                        >
                          Ma
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Mi"
                        >
                          Mi
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Ju"
                        >
                          Ju
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Vi"
                        >
                          Vi
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Sá"
                        >
                          Sá
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Do"
                        >
                          Do
                        </b-checkbox>
                      </div>
                      <br />
                      <div
                        class="d-flex align-items-center mb-4"
                        v-if="schedule.showValues"
                      >
                        <div class="col-md-6 pl-0">
                          <p>Desde:</p>
                          <input
                            v-model="schedule.hourFrom"
                            type="time"
                            value=""
                            class="mb-0"
                          />
                        </div>
                        <div class="col-md-6 pr-0">
                          <p>Hasta:</p>
                          <input
                            v-model="schedule.hourTo"
                            type="time"
                            value=""
                            class="mb-0"
                          />
                        </div>
                      </div>
                    </div>
                  </transition>
                  <div class="d-flex">
                    <!-- <input
                      type="text"
                      :value="[
                        schedule.days.join(', ') + ' - ' +
                        schedule.hourFrom + ' - ' + schedule.hourTo,
                      ]"
                      @click="schedule.showValues = !schedule.showValues"
                    /> -->
                    <div class="preview_box">
                      <p
                        class="text-preview"
                        @click="schedule.showValues = !schedule.showValues"
                      >
                        {{
                          schedule.days.join(', ') +
                          ' - ' +
                          schedule.hourFrom +
                          ' - ' +
                          schedule.hourTo
                        }}
                      </p>
                      <i
                        v-if="schedule.showValues"
                        class="fas fa-eye-slash"
                      ></i>
                      <i v-if="!schedule.showValues" class="fas fa-eye" />
                    </div>
                    <div
                      class="delete_box"
                      @click="deleteSchedule(scheduleIndex)"
                    >
                      <i
                        @click="deleteSchedule(scheduleIndex)"
                        class="fas fa-trash-alt"
                      ></i>
                    </div>
                  </div>
                  <!-- <input
                    class="input_static"
                    type="text"
                    :value="schedule.days.join(', ')"
                    disabled
                  /> -->
                  <hr
                    class="mt-3 mb-4"
                    :class="schedules.length == 6 ? 'not_here' : ''"
                    v-if="schedules.length >= 1"
                  />
                </div>
                <p
                  class="btn-text my-0"
                  :class="schedules.length == 6 ? 'not_here' : ''"
                  @click="addSchedule()"
                >
                  <i class="fas fa-plus" /> Agregar horario
                </p>
              </section>
              <label>Sitio web</label>
              <input
                alternative
                type="text"
                required
                v-model="chatbot.web"
                name="web"
                placeholder="https://www.nombre-empresa.cl"
              />
              <p v-if="error.web" class="text_error">
                Este campo debe contener www y/o https
              </p>
              <div class="d-flex justify-content-between align-items-center my-5">
                <button
                  class="btn btn-base"
                  :disabled="loading || view_permissions.add != true"
                  @click.prevent="save_bot"
                >
                  Crear chatbot
                  <span class="tooltip_text-sm for_disabled" v-if="view_permissions.add != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
                </button>
              </div>
              <router-link class="btn-text" to="/dashboard/chatbot"
                >&lt;- Volver</router-link
              >
            </div>
          </div>
          <!-- Columna preview -->
          <div class="col-lg-5">
            <div class="card card-chat">
              <div class="card-body">
                <Chatbot
                  :chatbot__trama="chatbot.trama_url"
                  :chatbot__logo="chatbot.bot_logo_url"
                  :chatbot__nombre="chatbot.bot_name"
                  :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
                  :chatbot__color__secundario="chatbot.bot_title_color"
                  :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
                  :chatbot__bot__color__letra="chatbot.bot_box_font_color"
                  :chatbot__user__color__fondo="chatbot.user_box_bg_color"
                  :chatbot__user__color__letra="chatbot.user_box_font_color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
  </div>
</template>

<script>
import '../../assets/buefy_class.scss';
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import Chatbot from '@/components/Chatbot.vue';
import timeZoneList from "@/plugins/timeZonesList.js";
import { FadeTransition } from "vue2-transitions";

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default {
  components: {
    Chatbot,
    FadeTransition
  },

  data() {
    return {
      loading: false,
      show: false,
      scrollPosition: null,
      emailloading: false,
      chatbot: {
        id: '',
        name: '',
        bot_logo_url: '',
        trama_url: '',
        bot_title: '',
        user_box_bg_color: '#ffffff',
        user_box_font_color: '#525252',
        bot_box_bg_color: '#D8EEFF',
        bot_box_font_color: '#ffffff',
        bot_title_bar_bg_color: '#333333',
        bot_title_color: '#ffffff',
        bot_name: 'Nombre chatbot',
        option_bg_color: '#ffffff',
        option_font_color: '#525252',
        business_types_id: null,
        web: '',
        rolagente: [],
        template: '',
        type_bot: false,
        init_conver: true,
        notification: false,
        message_end: '',
        // ocultar por vista
        desktop: true,
        mobile: true,
        init_conver_mobile: true,
        time_zone: ""
      },
      activated: false,
      business_type: [],
      user_bots: [
        {
          email: '',
        },
      ],
      error: {
        name: '',
        web: '',
        chatbot: '',
      },
      my_register: {
        step: '',
        product_type: '',
        bot: '',
        plan: '',
        billPlan: '',
        period: '',
        profile: '',
        channel: '',
      },
      custom: '',
      instemail: '',
      checkboxGroup: [],
      schedules: [],
      idSchedulesToDelete: [],
      showSchedule: false,
      newSchedule: 0,
      bots: [],
      image: '',
    };
  },

  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.setEcommerceBusinessId();
    this.chatbot.time_zone == "" ? this.chatbot.time_zone = TIMEZONE : this.chatbot.time_zone;
  },

  computed: {
    ...mapState([
      'user',
      'registerData',
      'active_view',
      'selected_bot',
      'ownerBots',
      'view_permissions'
    ]),
    isTimeZonesList() {
      return timeZoneList;
    },
    emailUser() {
      return this.$store.state.user.email;
    },
  },
  methods: {
    ...mapMutations(['setRegisterProcess', 'cleanRegisterData']),

    async setEcommerceBusinessId() {
      this.business_type = (
        await dashboard_api.get('/business_types/all_not_custom')
      ).data;
      this.chatbot.business_types_id = this.business_type.find(
        (el) => el.name == 'Ecommerce',
      ).id;
    },

    formatSchedule(scheduleFromDB) {
      const weekDays = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
      if (typeof scheduleFromDB.days === 'string') {
        scheduleFromDB.days = JSON.parse(scheduleFromDB.days);
      }
      const days = scheduleFromDB.days.map((day) => weekDays[day]);
      // let hourFrom = scheduleFromDB.range.split("-")[0];
      // let hourTo = scheduleFromDB.range.split("-")[1];
      // return { days, hourFrom, hourTo };
    },
    deleteSchedule(index) {
      if (this.schedules[index].id) {
        this.idSchedulesToDelete.push(this.schedules[index].id);
      }
      this.schedules.splice(index, 1);
    },
    addSchedule(id = null, days = [], hourFrom = '', hourTo = '') {
      if (this.schedules.length >= 6) return; // validacion de cantidad de horarios
      const newSchedule = {
        id,
        days,
        hourFrom,
        hourTo,
        showValues: !id,
      };
      this.schedules.push(JSON.parse(JSON.stringify(newSchedule)));
    },
    updateScheduleSkill() {
      // Darle formato al array a enviar
      const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
      const schedules = JSON.parse(JSON.stringify(this.schedules)); // clonando horarios para no modificar original
      for (const schedule of schedules) {
        schedule.days = schedule.days.map((day) => days.indexOf(day));
        schedule.range = `${schedule.hourFrom}-${schedule.hourTo}`;
      }
      const businessHours = schedules.filter((schedule, index) => {
        if (schedule.days.length > 0 && schedule.hourFrom && schedule.hourTo) {
          return true;
        }

        this.schedules.splice(index, 1);
        return false;
      });
      // actualizando horario en base de datos
      businessHours.forEach((businessHour, index) => {
        if (businessHour.id) {
          // actualizar registro
          // console.log("Aqui if");
          dashboard_api
            .put(`schedule/put/${businessHour.id}`, {
              days: businessHour.days,
              range: businessHour.range,
              timezone: TIMEZONE,
            })
            .then((response) => {});
        } else {
          // crear nuevo registro
          dashboard_api
            .post('schedule/newschedule', {
              days: businessHour.days,
              range: businessHour.range,
              bot_id: this.chatbot.id,
              timezone: TIMEZONE,
            })
            .then((response) => {
              this.schedules.splice(index, 1, {
                id: response.data.id,
                showValues: false,
                ...this.formatSchedule(response.data),
              });
            });
        }
      });

      // eliminando horarios
      Promise.all(
        this.idSchedulesToDelete.map((scheduleId) =>
          dashboard_api.delete(`schedules/${scheduleId}`),
        ),
      ).then(() => {
        this.idSchedulesToDelete = [];
      });
    },

    formDataBody(){
      const data = new FormData();
      const {
        bot_name,
        bot_title_bar_bg_color,
        business_types_id,
        bot_title_color,
        web,
        time_zone
      } = this.chatbot

      data.append("chatbot[bot_name]", bot_name ? bot_name : "Chatbot");
      data.append("chatbot[user_box_bg_color]", "#ffffff");
      data.append("chatbot[user_box_font_color]", "#525252");
      data.append("chatbot[bot_box_bg_color]", "#D8EEFF");
      data.append("chatbot[bot_box_font_color]", "#525252");
      data.append("chatbot[bot_title_bar_bg_color]", bot_title_bar_bg_color);
      data.append("chatbot[bot_title_color]", bot_title_color);
      data.append("chatbot[business_types_id]", business_types_id);
      data.append("chatbot[trama_url]", "");
      data.append("chatbot[web]", web);
      data.append("activated", true);
      data.append("chatbot[time_zone]", time_zone);
      if (this.image) data.append("chatbot[bot_avatar]", this.image);

      return data;
    },

    save_bot() {
      this.chatbot.bot_name.length < 1
        ? (this.error.name = true)
        : (this.error.name = false);
      this.chatbot.web.length < 7
        ? (this.error.web = true)
        : (this.error.web = false);
      this.error.web = !this.chatbot.web.includes(
        'www.' || 'https://' || 'http://',
      );
      this.error.chatbot = !this.chatbot.business_types_id;

      if (
        this.chatbot.web.length >= 3 &&
        this.chatbot.bot_name.length >= 1 &&
        this.chatbot.business_types_id
      ) {
        const self = this;
        self.loading = true;
        const formatData = self.formDataBody()
        dashboard_api
          .post('/bot', formatData)
          .then((bot) => {
            dashboard_api
              .post('/client_subscriptions', {
                user_id: self.user.id,
                bot_id: bot.data.id,
                status: false,
                paid_out: false,
              })
              .then((clientSubscriptionsResponse) => {
                self.loading = false;
                if (clientSubscriptionsResponse.status == 201) {
                  dashboard_api
                    .post('/userbot/createuserbot', {
                      user_bot: {
                        user_id: self.user.id,
                        bot_id: bot.data.id,
                        // role_id,
                        category: ["ventas", "facturación", "post-venta", "soporte", "general"]
                      }
                    })
                    .then(resp => {
                      console.log("resp userbot->", resp);
                    })
                    .catch(err => {});

                  self.$swal({
                    icon: 'success',
                    title: 'El chatbot se creó exitosamente',
                    timer: 1000,
                    showConfirmButton: false,
                  });

                  let newBots = self.ownerBots;
                  newBots.push(bot.data);

                  self.$store.commit('setOwnerBots', newBots);
                  self.$store.commit('setSelectedBot', bot.data);

                  self.my_register.bot = bot.data.id;

                  self.setRegisterProcess(self.my_register);
                  // TODO Sistema de planes con flow deprecado
                  // self.$router.push({
                  //   name: 'dashboard-planes',
                  //   query: {
                  //     seccion: 'new',
                  //     bot_id: bot.data.id,
                  //     from: 'nuevo-chatbot',
                  //   },
                  // });
                } else {
                  self.$swal({
                    icon: 'error',
                    title: 'Ocurrió un problema',
                    timer: 1000,
                    showConfirmButton: false,
                  });
                }
              })
              .catch((error) => console.error('error >', error));

            const bot_temp_id = bot.data.id;
            self.error.name = false;
            self.error.web = false;
          })
          .catch((error) => {
            console.log('error -> ', error);
          });
        if (self.showSchedule) {
          self.updateScheduleSkill();
        }
      }
    },

    increment_user_bot() {
      this.user_bots.push({
        email: '',
      });
    },
    decrement_user_bot() {
      this.user_bots.pop();
    },
    processFile(event) {
      const self = this;
      const reader = new FileReader();
      self.image = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        self.chatbot.bot_logo_url = reader.result;
      };
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbot_view {
  // padding: 6rem 0;

  .preview_box {
    width: 100%;
    position: relative;

    .fa-eye-slash,
    .fa-eye {
      position: absolute;
      color: #767676;
      font-size: 1rem;
      opacity: 0;
      top: 15px;
      right: 15px;
      // transition: .8s;
    }
    .text-preview {
      width: 100%;
      padding: 0.75rem;
      background-color: #f2f2f2;
      border-radius: 8px 0 0 8px;
      cursor: pointer;

      &:hover ~ .fa-eye-slash {
        opacity: 1 !important;
      }
      &:hover ~ .fa-eye {
        opacity: 1 !important;
      }
    }
  }
  .delete_box {
    padding: 0.75rem;
    width: 2.5rem;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    border: 1px solid #e8e8e8;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
    .fa-trash-alt {
      color: #767676;
      margin-bottom: 0;
      transition: 0.5s;
    }
  }
  .no-row {
    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-lg-5 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .card_box {
    padding: 0 2rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
    .title {
      margin-bottom: 3rem;
    }
  }
  .temas {
    margin-top: 2rem;

    &__item {
      .color_box {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        input {
          width: 50px;
          height: 52px;
          padding: 0;
          margin: auto 0;
          border: none;
          border-radius: none;
          background: transparent;
          cursor: pointer;

          &[type='color'] {
            margin-right: 0.5rem;
          }
          &[type='color']::-moz-color-swatch {
            box-shadow: none;
            border-radius: 5px;
          }
          &[type='color']::-webkit-color-swatch-wrapper {
            border-radius: 5px;
            border-style: none;
          }
          // edita la forma del input color
          &[type='color']::-webkit-color-swatch {
            box-shadow: none;
            border-radius: 5rem;
            border: 1px solid #cdcdcd;
          }
        }
      }
    }
  }
  .check__content {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bajada {
      color: #767676;
      font-weight: 400;
    }
  }
  .desktop_icon {
    width: 1.25rem;
    margin-left: 0.5rem;
  }
  .mobile_icon {
    width: 0.9rem;
    margin-left: 0.5rem;
  }
  select {
    width: 100%;
  }
  .checks_box {
    justify-content: space-around;
    display: flex;
    margin: auto;
  }
  .card-item {
    border: 1px solid #e8e8e8;
    border-radius: 1rem;
    padding: 1.5rem;

    .check_time {
      color: #767676;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
