<template>
  <section class="chatbot_view">
    <!-- <validationchat /> -->
    <validationbot />
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container mt-5">
        <div class="row no-row justify-content-center">
          <div class="col-lg-5 mb-5">
            <div class="card_box">
              <h2 class="mb-5">Edita {{ selected_bot.bot_name }}</h2>
              <div>
                <div class="label-box">
                  <label>Nombre del chatbot</label>
                  <span class="text_sm"
                    >{{ chatbot.bot_name.length }} / 15</span
                  >
                </div>
                <input
                  type="text"
                  required
                  v-model="chatbot.bot_name"
                  name="bot_name"
                  placeholder="Julieta"
                  maxlength="15"
                />
                <p v-if="error.name" class="text_error">
                  Ingresa un nombre para el chatbot
                </p>
                <label>Imagen de perfil</label>
                <input
                  type="file"
                  @change="processFile($event)"
                  name="bot_logo_url"
                />
                <br />
                <br />
                <div class="field buefy">
                  <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label class="mr-2"
                        >¿Desea que este chatbot envíe notificaciones por
                        correo?</label
                      >
                      <b-switch v-model="chatbot.notification" />
                    </div>
                    <p class="info">
                      El chatbot enviará notificaciones por correo a todos los
                      administradores y agentes cuando se registra un nuevo
                      Lead.
                    </p>
                  </div>
                  <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label class="mr-2"
                        >Activar opciones de accesibilidad</label
                      >
                      <b-switch v-model="chatbot.accessibility" />
                    </div>
                    <p class="info">
                      Los usuarios podrán modificar el tamaño de la letra de los
                      mensajes en el chatbot para facilitar su lectura.
                    </p>
                  </div>
                  <div class="two_box two_box-lg my-4">
                    <div class="info_box">
                      <div class="label-box">
                        <label class="mr-2"
                          >¿Persistencia de conversación?</label
                        >
                        <b-switch v-model="chatbot.has_persistence" />
                      </div>
                      <p class="info mb-0">
                        Recuerda conversaciones con usuarios que interactuaron antes.
                      </p>
                    </div>
                    <template v-if="plan_name != 'Plan enterprise'">
                      <!-- TODO Sistema de planes con flow deprecado -->
                      <!-- <router-link
                        class="no_click"
                        :to="{
                          name: 'dashboard-planes',
                          query: {
                            seccion: 'mejorar-plan',
                            bot_id: selected_bot.id,
                          },
                        }"
                      >
                        <div class="info_box">
                          <div class="label-box">
                            <label class="text-disabled mr-2"
                              >¿Utilizar imagen de perfil en la burbuja de la
                              ventana?</label
                            >
                            <b-switch disabled />
                          </div>
                          <p class="info text-disabled mb-0">
                            ¡Tu imagen de marca se verá en todo momento!
                          </p>
                          <span class="upgrade-label">Mejorar plan</span>
                        </div>
                      </router-link> -->
                      <div class="info_box">
                        <div class="label-box">
                          <label class="text-disabled mr-2"
                            >¿Utilizar imagen de perfil en la burbuja de la
                            ventana?</label
                          >
                          <b-switch disabled />
                        </div>
                        <p class="info text-disabled mb-0">
                          ¡Tu imagen de marca se verá en todo momento!
                        </p>
                        <span class="upgrade-label">Mejorar plan</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="info_box">
                        <div class="label-box">
                          <label class="mr-2"
                            >¿Utilizar imagen de perfil en la burbuja de la
                            ventana?</label
                          >
                          <b-switch v-model="chatbot.img_bubble" />
                        </div>
                        <p class="info mb-0">
                          ¡Tu imagen de marca se verá en todo momento!
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <hr class="my-5" />
                <label class="mb-4">Mostrar chatbot en:</label>
                <div class="check__content">
                  <b-checkbox class="bajada" v-model="chatbot.desktop"
                    >Escritorio
                    <img
                      class="desktop_icon"
                      src="/img/icons/widgetWhatsapp/3.1-desktop.svg"
                      alt="Escritorio"
                    />
                  </b-checkbox>
                  <b-checkbox class="bajada" v-model="chatbot.mobile"
                    >Móvil
                    <img
                      class="mobile_icon"
                      src="/img/icons/widgetWhatsapp/3.2-mobile.svg"
                      alt="movil"
                    />
                  </b-checkbox>
                </div>
                <hr class="my-5" />
                <label>¿Abrir ventana automáticamente en escritorio?</label>
                <select v-model="chatbot.init_conver" placeholder="Seleccionar">
                  <option :value="null">Seleccionar</option>
                  <option :value="true">Si (Recomendado)</option>
                  <option :value="false">No</option>
                </select>
                <label>¿Abrir ventana automáticamente en mobile?</label>
                <select
                  v-model="chatbot.init_conver_mobile"
                  placeholder="Seleccionar"
                >
                  <option :value="null">Seleccionar</option>
                  <option :value="true">Si</option>
                  <option :value="false">No (Recomendado)</option>
                </select>
                <hr class="my-5" />
                <div class="temas">
                  <!-- color pestaña -->
                  <div class="temas__item">
                    <label>Color ventana del chatbot</label>
                    <p>
                      El color primario corresponde al color de la barra
                      superior, la burbuja y el botón enviar, el color
                      secundario corresponde al color de las letras.
                    </p>
                    <div class="color_box">
                      <input
                        type="color"
                        v-model="chatbot.bot_title_bar_bg_color"
                        name="bot_title_bar_bg_color"
                      />
                      <p class="mb-0 mr-3">Primario</p>
                      <input
                        type="color"
                        v-model="chatbot.bot_title_color"
                        name="bot_title_color"
                      />
                      <p class="mb-0 mr-3">Secundario</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-5" />
              <!-- <label v-if="custom === true">Tipo de negocio</label>
              <select
                v-model="chatbot.business_types_id"
                name="bot_font"
                required
                selected
                v-if="custom === true"
              >
                <option
                  v-for="business in business_type"
                  :value="business.id"
                  :key="business.id"
                >
                  {{ business.name }}
                </option>
              </select> -->
              <label>Zona horaria</label>
              <select v-model="chatbot.time_zone">
                <option v-for="(zone, index) in isTimeZonesList" :key="index" :value="zone">{{ zone }}</option>
              </select>
              <label>Horario de contacto</label>
              <section class="card mb-3">
                <div
                  v-for="(schedule, scheduleIndex) in schedules"
                  :key="scheduleIndex"
                >
                  <transition name="fade">
                    <div v-if="schedule.showValues">
                      <div class="checks_box">
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Lu"
                        >
                          Lu
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Ma"
                        >
                          Ma
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Mi"
                        >
                          Mi
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Ju"
                        >
                          Ju
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Vi"
                        >
                          Vi
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Sá"
                        >
                          Sá
                        </b-checkbox>
                        <b-checkbox
                          class="check_time"
                          v-model="schedule.days"
                          native-value="Do"
                        >
                          Do
                        </b-checkbox>
                      </div>
                      <br />
                      <div
                        class="d-flex align-items-center mb-4"
                        v-if="schedule.showValues"
                      >
                        <div class="col-md-6 pl-0">
                          <p class="mb-2">Desde:</p>
                          <input
                            v-model="schedule.hourFrom"
                            type="time"
                            value=""
                            class="mb-0"
                          />
                        </div>
                        <div class="col-md-6 pr-0">
                          <p class="mb-2">Hasta:</p>
                          <input
                            v-model="schedule.hourTo"
                            type="time"
                            value=""
                            class="mb-0"
                          />
                        </div>
                      </div>
                    </div>
                  </transition>
                  <div class="d-flex">
                    <!-- <input
                        type="text"
                        :value="[
                          schedule.days.join(', ') + ' - ' +
                          schedule.hourFrom + ' - ' + schedule.hourTo,
                        ]"
                        @click="schedule.showValues = !schedule.showValues"
                      /> -->
                    <div class="preview_box">
                      <p
                        class="text-preview"
                        @click="schedule.showValues = !schedule.showValues"
                      >
                        {{
                          schedule.days.join(', ') +
                          ' - ' +
                          schedule.hourFrom +
                          ' - ' +
                          schedule.hourTo
                        }}
                      </p>
                      <i
                        v-if="schedule.showValues"
                        class="fas fa-eye-slash"
                      ></i>
                      <i v-if="!schedule.showValues" class="fas fa-eye" />
                    </div>
                    <div
                      class="delete_box"
                      @click="deleteSchedule(scheduleIndex)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </div>
                  </div>
                  <!-- <input
                      class="input_static"
                      type="text"
                      :value="schedule.days.join(', ')"
                      disabled
                    /> -->
                  <hr
                    class="mt-3 mb-4"
                    :class="schedules.length == 6 ? 'not_here' : ''"
                    v-if="schedules.length >= 1"
                  />
                </div>
                <p
                  class="btn-text my-0"
                  :class="schedules.length == 6 ? 'not_here' : ''"
                  @click="addSchedule()"
                >
                  <i class="fas fa-plus" /> Agregar horario
                </p>
              </section>
              <!-- fin horario comercial -->
              <label>Sitio web</label>
              <input
                type="text"
                required
                v-model="chatbot.web"
                name="web"
                placeholder="www.ejemplo.cl"
              />
              <p v-if="error.web" class="text_error">
                Ingresar una página web para el chatbot
              </p>
              <div
                class="d-flex justify-content-between align-items-center my-5"
              >
                <button
                  class="btn btn-base"
                  type="submit"
                  :disabled="loading || view_permissions.edit != true"
                  @click.prevent="save_bot"
                >
                  Guardar cambios
                  <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
                </button>
                <button
                  v-show="chatbot.activated"
                  class="btn btn-base outline"
                  @click="show = true"
                >
                  Implementar
                </button>
              </div>
              <router-link class="btn-text" to="/dashboard">&lt;- Volver</router-link>
            </div>
          </div>
          <!-- Columna preview -->
          <div class="col-lg-5">
            <div class="card card-chat">
              <div class="card-body">
                {{ chatbot.trama_url }}
                <Chatbot
                  :chatbot__trama="chatbot.trama_url"
                  :chatbot__logo="imagePreview"
                  :chatbot__nombre="chatbot.bot_name"
                  :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
                  :chatbot__color__secundario="chatbot.bot_title_color"
                  :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
                  :chatbot__bot__color__letra="chatbot.bot_box_font_color"
                  :chatbot__user__color__fondo="chatbot.user_box_bg_color"
                  :chatbot__user__color__letra="chatbot.user_box_font_color"
                  :accessibility="chatbot.accessibility"
                  :bubble="chatbot.img_bubble"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
    <Implementacion
      :show="show"
      :bot_id="chatbot.id"
      :bot_token="chatbot.token"
      @close="show = false"
    />
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import { mapMutations, mapState } from 'vuex';
// import validationchat from './components/validationchat.vue';
import validationbot from './components/validationbot.vue';
import Implementacion from '@/components/Implementacion.vue';
import dashboard_api from '@/dashboard_api.js';
import Chatbot from '@/components/Chatbot.vue';
import timeZoneList from "@/plugins/timeZonesList.js";
import { FadeTransition } from "vue2-transitions";

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default {
  components: {
    Implementacion,
    Chatbot,
    // validationchat,
    validationbot,
    FadeTransition
},
  data() {
    return {
      loading: true,
      show: false,
      emailloading: false,
      chatbot: {
        id: '',
        name: '',
        bot_logo_url: '',
        trama_url: '',
        bot_title: '',
        user_box_bg_color: '#ffffff',
        user_box_font_color: '#525252',
        bot_box_bg_color: '#D8EEFF',
        bot_box_font_color: '#ffffff',
        bot_title_bar_bg_color: '#181818',
        bot_title_color: '#ffffff',
        bot_name: '',
        option_bg_color: '#ffffff',
        option_font_color: '#525252',
        web: '',
        rolagente: [],
        template: '',
        type_bot: false,
        notification: false,
        message_end: '',
        accessibility: false,
        img_bubble: false,
        desktop: false,
        mobile: false,
        init_conver: true,
        init_conver_mobile: false,
        time_zone: ""
      },
      business_type: [],
      user_bots: [
        {
          email: '',
        },
      ],
      error: {
        name: '',
        web: '',
      },
      custom: '',
      instemail: '',
      // horario comercial
      checkboxGroup: [],
      schedules: [],
      idSchedulesToDelete: [],
      showSchedule: true,
      image: '',
      imagePreview: ''
    };
  },
  created() {
    const self = this;
    window.scrollTo(0, 0);

    dashboard_api.get('/business_types/all_not_custom').then((response) => {
      self.business_type = response.data;
    });
  },

  computed: {
    ...mapState(['user', 'selected_bot', 'ownerBots', 'plan_name', 'view_permissions']),

    isTimeZonesList() {
      return timeZoneList;
    },
    bot_id() {
      return this.selected_bot.id;
    },
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getChatbotEdit();
      }
    },
  },
  methods: {
    ...mapMutations(['editSelectedBot']),

    async getChatbotEdit() {
      let self = this;

      self.chatbot = (
        await dashboard_api.get(`/bot/showedit/${self.bot_id}`)
      ).data;
      self.chatbot.time_zone == "" ? self.chatbot.time_zone = TIMEZONE : self.chatbot.time_zone;
      self.imagePreview = self.chatbot.bot_avatar || self.chatbot.bot_logo_url;
      console.log("chatbot->", self.chatbot);

      const businessType = (
        await dashboard_api.get(`/get_bot_info/${self.chatbot.id}`)
      ).data.business_type;
      self.custom = businessType.show;

      // inicializando horarios comerciales
      if (self.showSchedule) {
        let resp = (await dashboard_api.get(`schedule/list/${self.chatbot.id}`))
          .data;

        resp.forEach((el) => {
          let formatted = self.formatSchedule(el);
          self.addSchedule(
            el.id,
            formatted.days,
            formatted.hourFrom,
            formatted.hourTo,
          );
        });

        // pasa a false para evitar duplicado al guardar
        self.showSchedule = false;
      }
    },

    formatSchedule(scheduleFromDB) {
      const weekDays = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
      if (typeof scheduleFromDB.days === 'string') {
        scheduleFromDB.days = JSON.parse(scheduleFromDB.days);
      }
      const days = scheduleFromDB.days.map((day) => weekDays[day]);
      const hourFrom = scheduleFromDB.range.split('-')[0];
      const hourTo = scheduleFromDB.range.split('-')[1];
      return { days, hourFrom, hourTo };
    },

    deleteSchedule(index) {
      if (this.schedules[index].id) {
        this.idSchedulesToDelete.push(this.schedules[index].id);
      }
      this.schedules.splice(index, 1);

      // this.$swal({
      //   toast: true,
      //   icon: 'success',
      //   text: 'Eliminado',
      //   position: 'top-end',
      //   showConfirmButton: false,
      //   timer: 1000,
      //   timerProgressBar: false,
      // });
    },

    addSchedule(id = null, days = [], hourFrom = '', hourTo = '') {
      if (this.schedules.length >= 6) return; // validacion de cantidad de horarios
      const newSchedule = {
        id,
        days,
        hourFrom,
        hourTo,
        showValues: !id,
      };
      this.schedules.push(JSON.parse(JSON.stringify(newSchedule)));
    },

    updateScheduleSkill() {
      // Darle formato al array a enviar
      const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
      let schedules = JSON.parse(JSON.stringify(this.schedules));

      // clonando horarios para no modificar original
      for (const schedule of schedules) {
        schedule.days = schedule.days.map((day) => days.indexOf(day));
        schedule.range = `${schedule.hourFrom}-${schedule.hourTo}`;
      }
      const businessHours = schedules.filter((schedule, index) => {
        if (schedule.days.length > 0 && schedule.hourFrom && schedule.hourTo) {
          return true;
        }

        this.schedules.splice(index, 1);
        return false;
      });
      // actualizando horario en base de datos
      businessHours.forEach((businessHour, index) => {
        if (businessHour.id) {
          // actualizar registro
          dashboard_api
            .put(`schedule/put/${businessHour.id}`, {
              days: businessHour.days,
              range: businessHour.range,
              bot_id: this.chatbot.id,
              timezone: TIMEZONE,
            })
            .then((response) => {});
        } else {
          // crear nuevo registro
          dashboard_api
            .post('schedule/newschedule', {
              days: businessHour.days,
              range: businessHour.range,
              bot_id: this.chatbot.id,
              timezone: TIMEZONE,
            })
            .then((response) => {
              this.schedules.splice(index, 1, {
                id: response.data.id,
                showValues: false,
                ...this.formatSchedule(response.data),
              });
            });
        }
      });

      // eliminando horarios
      Promise.all(
        this.idSchedulesToDelete.map((scheduleId) =>
          dashboard_api.delete(`schedules/${scheduleId}`),
        ),
      ).then(() => {
        this.idSchedulesToDelete = [];
      });
    },

    formDataBody() {
      const data = new FormData();

      const {
        bot_name,
        bot_title_bar_bg_color,
        business_types_id,
        bot_title_color,
        notification,
        accessibility,
        has_persistance,
        img_bubble,
        desktop,
        mobile,
        init_conver,
        init_conver_mobile,
        web,
        trama_url,
        time_zone
      } = this.chatbot;

      data.append("chatbot[bot_name]", bot_name || "Chatbot");
      data.append("chatbot[bot_logo_url]", "");
      data.append("chatbot[user_box_bg_color]", "#ffffff");
      data.append("chatbot[user_box_font_color]", "#525252");
      data.append("chatbot[bot_box_bg_color]", "#D8EEFF");
      data.append("chatbot[bot_box_font_color]", "#525252");
      data.append("chatbot[bot_title_bar_bg_color]", bot_title_bar_bg_color);
      data.append("chatbot[bot_title_color]", bot_title_color);
      data.append("chatbot[business_types_id]", business_types_id);
      data.append("chatbot[notification]", notification);
      data.append("chatbot[accessibility]", accessibility);
      data.append("chatbot[has_persistance]", has_persistance);
      data.append("chatbot[img_bubble]", img_bubble);
      data.append("chatbot[desktop]", desktop);
      data.append("chatbot[mobile]", mobile);
      data.append("chatbot[init_conver]", init_conver);
      data.append("chatbot[init_conver_mobile]", init_conver_mobile);
      data.append("chatbot[web]", web);
      data.append("chatbot[trama_url]", trama_url);
      data.append("chatbot[time_zone]", time_zone);

      if (this.image) data.append("chatbot[bot_avatar]", this.image);

      return data;
    },

    save_bot() {
      const self = this;
      const formData = self.formDataBody();

      self.$swal.showLoading();

      dashboard_api
        .put(`/bot/${self.chatbot.id}`, formData)
        .then((response) => {
          self.editSelectedBot(response.data);
          self.$swal({
            icon: 'success',
            title: 'El chatbot se actualizó exitosamente',
          });
          // self.setRegisterProcess(self.my_register);
        })
        .catch(error => console.log("put =>", error));
      // actualizar horario comercial en skill del bot
      self.updateScheduleSkill();
    },

    increment_user_bot() {
      this.user_bots.push({
        email: '',
      });
    },

    decrement_user_bot() {
      this.user_bots.pop();
    },

    processFile(event) {
      const self = this;

      const reader = new FileReader();
      self.image = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        self.imagePreview = reader.result;
        self.chatbot.bot_logo_url = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    copyTestingCode() {
      const testingCodeToCopy = document.getElementById('scriptBot');
      testingCodeToCopy.select();
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        alert(`Testing code was copied ${msg}`);
      } catch (err) {
        alert('Oops, unable to copy');
      }
      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },

    sendmail() {
      const self = this;
      self.emailloading = true;
      dashboard_api
        .post('/mails/implement', {
          email: self.instemail,
          bot_id: self.chatbot.id,
        })
        .then((response) => {
          self.$swal({
            icon: 'success',
            title: 'Se ha enviado el correo correctamente!',
          });
          self.show = false;
          self.emailloading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbot_view {
  .preview_box {
    width: 100%;
    position: relative;

    .fa-eye-slash,
    .fa-eye {
      position: absolute;
      color: #767676;
      font-size: 1rem;
      opacity: 0;
      top: 15px;
      right: 15px;
      // transition: .8s;
    }
    .text-preview {
      width: 100%;
      padding: 0.75rem;
      background-color: #f2f2f2;
      border-radius: 8px 0 0 8px;
      cursor: pointer;

      &:hover ~ .fa-eye-slash {
        opacity: 1 !important;
      }
      &:hover ~ .fa-eye {
        opacity: 1 !important;
      }
    }
  }
  .delete_box {
    padding: 0.75rem;
    width: 2.5rem;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    border: 1px solid #e8e8e8;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
    .fa-trash-alt {
      color: #767676;
      margin-bottom: 0;
      transition: 0.5s;
    }
  }
  .no-row {
    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-lg-5 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .card_box {
    padding: 0 2rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
  }
  .temas {
    margin-top: 2rem;

    &__item {
      .color_box {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        input {
          width: 50px;
          height: 54px;
          padding: 0;
          margin: auto 0;
          border: none;
          background: transparent;
          cursor: pointer;

          &[type='color'] {
            margin-right: 0.5rem;

            &::-moz-color-swatch {
              border-radius: 5px;
            }
            &::-webkit-color-swatch-wrapper {
              border-radius: 5px;
              border-style: none;
            }
            // edita la forma del input color
            &::-webkit-color-swatch {
              border-radius: 5rem;
              border: 1px solid #cdcdcd;
            }
          }
        }
      }
    }
  }
  .check__content {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bajada {
      color: #767676;
      font-weight: 400;
    }
  }
  .desktop_icon {
    width: 1.25rem;
    margin-left: 0.5rem;
  }
  .mobile_icon {
    width: 0.9rem;
    margin-left: 0.5rem;
  }
  select {
    width: 100%;
  }
  .checks_box {
    justify-content: space-between;
    display: flex;
    margin: auto;
  }
  .card {
    padding: 1.5rem;
    border-color: #cdcdcd;

    .check_time {
      color: #767676;
    }
  }
  .no_click {
    text-decoration: none;
  }
}
</style>