<template>
  <div class="join-conversation">
    <span class="line"></span>
    <span class="text"><img class="img-sm mb-1 mr-2" :src="imgAgent" alt=""> {{ message.text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imgAgent() {
      return this.user.image || "img/brand/0-default.svg";
    }
  }
};
</script>

<style lang="scss" scoped>
.join-conversation {
  position: relative;
  text-align: center;
  margin: 2rem 0;
  color: #b2b2b2;
  padding: .5rem 0;

  .line {
    width: 100%;
    top: 1.5rem;
    bottom: 0;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-top: 1px solid #e8e8e8;
  }
  .img-sm {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    object-fit: cover;
    object-position: center;
    border: 1px solid #f2f2f2;
    z-index: 5;
  }
  .text {
    position: relative;
    background-color: #fff;
    padding: .5rem 1rem;
    z-index: 5;
  }
}
</style>
