<template>
  <section class="edit-info">
    <div v-for="(element, index) in elements" :key="index">
      <div v-if="element.output_nodo.response_type === 'text'" class="my-3">
        <div class="label-box">
          <label>Respuesta</label>
          <div class="d-flex align-items-center">
            <p
              class="btn-text pr-2 text-decoration-none"
              @click="copyExample(element)"
            >
              Copiar Ejemplo<i class="fas fa-copy ml-2" />
            </p>
          </div>
        </div>
        <textarea
          class="info_box"
          cols="30"
          rows="4"
          maxlength="300"
          :placeholder="element.placeholder"
          v-model="element.output_nodo.values[0].text"
        />
        <div class="d-flex justify-content-end pr-2">
          <p class="text_sm">
            {{ element.output_nodo.values[0].text.length }} / 300
          </p>
        </div>
      </div>
      <div v-if="false" class="my-5">
        <template v-if="question.name != 'sucursales'">
          <div class="my-3" v-if="question.label_switch_1">
            <div class="label-box">
              <label>{{ question.label_switch_1 }}</label>
              <div class="buefy">
                <b-switch v-model="question.switch_1" />
              </div>
            </div>
            <br />
          </div>
          <div v-if="question.label_area_text_1" class="my-3">
            <div class="label-box">
              <label>{{ question.label_area_text_1 }}</label>
              <p class="text_sm">{{ question.area_text_1.length }} / 300</p>
            </div>
            <textarea
              class="info_box"
              cols="30"
              rows="4"
              maxlength="300"
              placeholder="Escribe una respuesta aquí"
              v-model="question.area_text_1"
            />
          </div>
          <div v-if="question.change_skill">
            <router-link
              :to="
                `/chatbot-center/skill-base/sucursales-y-ubicacion?bot_id=${bot.id}`
              "
            >
              <p class="btn-text">
                {{ question.change_skill }}<i class="fas fa-arrow-right ml-2" />
              </p>
            </router-link>
          </div>
          <div v-if="question.radios_1" class="label-box my-3">
            <div
              v-for="radio in question.radios_1"
              :key="radio.id"
              class="d-flex align-items-center"
            >
              <input
                type="radio"
                :id="radio.id"
                v-model="question.radios_1[0].value"
                :value="radio.value"
                name="radios"
              />
              <label class="p_text mb-0 ml-1" :for="radio.id">{{
                radio.label
              }}</label>
            </div>
          </div>
          <br />
          <div v-if="question.label_input_1" class="my-3">
            <label class="mb-2">{{ question.label_input_1 }}</label>
            <p v-if="question.recomendation_1" class="mb-3">
              {{ question.recomendation_1 }}
            </p>
            <input
              type="text"
              :placeholder="question.placeholder_input_1"
              v-model="question.input_1"
            />
          </div>
          <div
            v-if="question.multi_input_1 && question.multi_input_1.length >= 1"
            class="my-3"
          >
            <div class="label-box">
              <label>{{ question.label_multi_input_1 }}</label>
              <p class="text_sm">
                {{ question.multi_input_1.length }}
                {{ question.multi_input_1.length == 1 ? 'Código' : 'Códigos' }}
              </p>
            </div>
            <div
              v-for="(input, index2) in question.multi_input_1"
              :key="index2"
            >
              <div class="delete-input">
                <input
                  type="text"
                  :placeholder="question.place_multi_input_1"
                  v-model="input.model"
                />
                <button
                  @click="deletedCodeInput(question.multi_input_1, index2)"
                >
                  <i class="fas fa-trash-alt" />
                </button>
              </div>
            </div>
            <button
              class="btn-dash"
              @click="addCodeInput(question.multi_input_1)"
            >
              +
            </button>
          </div>
          <div
            v-else-if="
              question.multi_input_1 && question.multi_input_1.length == 0
            "
            class="info_box my-3"
            @click="addCodeInput(question.multi_input_1)"
          >
            <p class="text-center">Agrega un código de promoción aquí</p>
          </div>
        </template>
        <!-- sucursales tiene una estructura distinta -->
        <template v-else>
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <label>Nombre sucursal</label>
              <input
                class="mb-3"
                type="text"
                placeholder="Ejem: Sucursal Alameda"
                v-model="question.office_name"
                required
              />
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <label>Dirección</label>
              <input
                class="mb-3"
                type="text"
                placeholder="Av. Pajaritos 2233, Santiago"
                v-model="question.office_adress"
                required
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <label>Horario de atención</label>
              <div class="row justify-content-between">
                <div class="col-lg-6 pl-0 pr-2">
                  <input
                    class="mb-3"
                    type="time"
                    v-model="question.office_init"
                    required
                  />
                </div>
                <div class="col-lg-6 pl-2 pr-0">
                  <input
                    class="mb-3"
                    type="time"
                    v-model="question.office_end"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <label>URL Ubicación</label>
              <input
                class="mb-3"
                type="url"
                placeholder="https://empresa/sucursal-1"
                v-model="question.office_url"
                required
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <label>Encargado</label>
              <input
                class="mb-3"
                type="text"
                placeholder="Ejem: Carlos"
                v-model="question.office_manager"
                required
              />
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <label>Disponible para retiro en tienda</label>
              <select
                class="w-100 mb-3"
                v-model="question.office_pickup"
                required
              >
                <option value disabled>Seleccionar</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-6 pl-0 pr-2">
              <label>Teléfono</label>
              <input
                class="mb-3"
                type="text"
                placeholder="569 1234 5678"
                v-model="question.office_phone"
              />
            </div>
            <div class="col-lg-6 pr-0 pl-2">
              <label>Otro</label>
              <input
                class="mb-3"
                type="text"
                placeholder="Ejem: Cerca del centro"
                v-model="question.office_ref"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import '@/assets/buefy_class.scss';
import { mapState } from 'vuex';

export default {
  components: {
    FadeTransition,
  },

  props: ['selected', 'bot', 'selectedIntent'],
  mounted() {
    this.initialize();
  },

  data() {
    return {
      elements: [],
      items: [
        {
          name: 'gestion',
          questions: [
            {
              name: 'seguimiento',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://seguimiento.empresa.cl/',
              input_1: '',
              status: false,
            },
            {
              name: 'dirección',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes editar la información en el módulo cuenta, sección pedidos. También puedes enviar un correo a:',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://empresa.cl/cuenta/pedidos',
              input_1: '',
              status: false,
            },
            {
              name: 'editar-pedido',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes editar la información en el módulo cuenta, sección pedidos. También puedes enviar un correo soporte@empresa.cl',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://empresa.cl/cuenta/pedidos',
              input_1: '',
              status: false,
            },
            {
              name: 'cancelar-pedido',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes cancelar tu pedido desde tu cuenta, sección pedidos. Reembolsaremos el total de la compra en 10 días hábiles.',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://empresa.cl/cuenta/pedidos',
              input_1: '',
              status: false,
            },
            {
              name: 'retirar-pedido',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes retirar tus productos directamente en nuestras sucursales.',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://empresa.cl/cuenta/pedidos',
              input_1: '',
              status: false,
            },
            {
              name: 'recibir-pedido',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes cancelar tu pedido desde tu cuenta, sección pedidos. Reembolsaremos el total de la compra en 10 días hábiles.',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://empresa.cl/cuenta/pedidos',
              input_1: '',
              status: false,
            },
            {
              name: 'boleta-pedido',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'La boleta se adjunta en el correo de compra, también puedes encontrarla en la sección cuenta, mis pedidos "boleta".',
              label_input_1: 'Dirección URL para la sección',
              placeholder_input_1: 'Ejem: https://empresa.cl/cuenta/pedidos',
              input_1: '',
              status: false,
            },
          ],
        },
        {
          name: 'envios',
          questions: [
            {
              name: 'retiro',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes retirar tus productos directamente en nuestras sucursales',
              label_switch_1: 'Mostrar sucursales ingresadas.',
              switch_1: false,
              change_skill: 'Editar sucursales',
              status: false,
            },
            {
              name: 'ubicacion',
              label_area_text_1: 'Respuesta',
              area_text_1: 'Nuestras sucursales están ubicadas en:',
              label_switch_1: 'Mostrar sucursales ingresadas.',
              switch_1: false,
              change_skill: 'Editar sucursales',
              status: false,
            },
            {
              name: 'empresa',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Trabajamos con las siguientes empresas: Starken y Blue Express',
              status: false,
            },
            {
              name: 'tiempos',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Realizamos envios a todo el territorio nacional. El tiempo de entrega en Santiago es de 2 a 5 días hábiles con un costo de $5.000. Para el resto del país, es de 5 a 10 días hábiles y el costo dependerá de la ubicación.',
              status: false,
            },
            {
              name: 'adelantar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Si necesitas adelantar tu pedido por favor comunícate al siguiente número +569 1234 5678',
              status: false,
            },
            {
              name: 'despachan a',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Realizamos envíos a todo el territorio nacional. El tiempo de entrega en Santiago es de 2 a 5 días hábiles con un costo de $5.000. Para el resto del país, es de 5 a 10 días hábiles y el costo dependerá de la ubicación.',
              status: false,
            },
            {
              name: 'express',
              label_area_text_1: 'Respuesta',
              area_text_1: 'Si tenemos y el valor es de $15.000',
              status: false,
            },
            {
              name: 'horarios',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Realizamos entregas de lunes a viernes entre 9 y 18hrs. Los sábados de 10 a 14hrs',
              status: false,
            },
            {
              name: 'cambiar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Puedes editar la información en el módulo cuenta, sección pedidos. También puedes enviar un correo a pedido@empresa.cl',
              status: false,
            },
          ],
        },
        {
          name: 'productos',
          questions: [
            {
              name: 'retiro',
              label_switch_1: 'Activar recomendado de productos con IA.',
              switch_1: false,
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Si los productos aparecen disponibles en la página web es porque existe stock.',
              radios_1: [
                {
                  id: 'radio_1',
                  label: 'Continuar conversación',
                  model: '',
                  value: 'continuar',
                },
                {
                  id: 'radio_2',
                  label: 'Solicitar ejecutivo',
                  model: '',
                  value: 'ejecutivo',
                },
                {
                  id: 'radio_3',
                  label: 'Terminar conversación',
                  model: '',
                  value: 'terminar',
                },
              ],
            },
            {
              name: 'ubicacion',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'descuentos',
              label_multi_input_1: 'Códigos válidos',
              place_multi_input_1: 'Ingresa un código',
              multi_input_1: [
                {
                  model: '',
                },
                {
                  model: '',
                },
                {
                  model: '',
                },
              ],
              status: false,
            },
            {
              name: 'tiempos',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'adelantar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'despachan a',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'express',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'entregas',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'cambiar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
          ],
        },
        {
          name: 'cambios',
          questions: [
            {
              name: 'retiro',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Seguimiento de pedido',
              recomendation_1:
                'Ingresa la url para que tu cliente pueda ver el seguimiento de su pedido.',
              placeholder_input_1: 'Ejem: https://seguimiento.empresa.cl/',
              input_1: '',
              status: false,
            },
            {
              name: 'ubicacion',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'empresa',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'tiempos',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'adelantar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'despachan a',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'express',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'entregas',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'cambiar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
          ],
        },
        {
          name: 'pagos',
          questions: [
            {
              name: 'retiro',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Seguimiento de pedido',
              recomendation_1:
                'Ingresa la url para que tu cliente pueda ver el seguimiento de su pedido.',
              placeholder_input_1: 'Ejem: https://seguimiento.empresa.cl/',
              input_1: '',
              status: false,
            },
            {
              name: 'ubicacion',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'empresa',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'tiempos',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'adelantar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'despachan a',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'express',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'entregas',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'cambiar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
          ],
        },
        {
          name: 'sucursales',
          questions: [
            {
              name: 'sucursales',
              office_name: '',
              office_adress: '',
              office_init: '',
              office_end: '',
              office_url: '',
              office_manager: '',
              office_pickup: '',
              office_phone: '',
              office_ref: '',
              status: false,
            },
          ],
        },
        {
          name: 'faqs',
          questions: [
            {
              name: 'retiro',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Seguimiento de pedido',
              recomendation_1:
                'Ingresa la url para que tu cliente pueda ver el seguimiento de su pedido.',
              placeholder_input_1: 'Ejem: https://seguimiento.empresa.cl/',
              input_1: '',
              status: false,
            },
            {
              name: 'ubicacion',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'empresa',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'tiempos',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'adelantar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'despachan a',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'express',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'entregas',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
            {
              name: 'cambiar',
              label_area_text_1: 'Respuesta',
              area_text_1:
                'Para dar seguimiento a tu pedido, haz click en el siguiente link 👇:',
              label_input_1: 'Dirección',
              recomendation_1: '',
              placeholder_input_1:
                'Ejem: Av. Nueva Providencia, 3501. Providencia. Santiago',
              input_1: '',
              status: false,
            },
          ],
        },
      ],
    };
  },
  // created() {
  //   console.log('questions->', this.items);
  // },
  computed: {
    ...mapState(['selected_bot', 'skill_template']),
  },
  methods: {
    async initialize() {
      // getting intent content
      const intentName = this.selectedIntent.intent_name;
      console.log('🚀 Aqui *** -> intentName:', intentName);
      if (intentName != 'undefined') {
        this.elements = await this.$store.dispatch(
          'dtNodesModule/byIntentName',
          { name: intentName, botId: this.selected_bot.id, template: this.skill_template.value },
        );
      } else {
        this.elements = [];
      }
      // adding placeholder field
      this.elements.forEach(element => {
        if (element.status === false) {
          element.placeholder =
            'Ejemplo: ' + element.output_nodo.values[0].text;
          element.output_nodo.values[0].text = '';
        } else {
          element.output_nodo.values[0].text;
        }
      });
    },
    addCodeInput(inputs) {
      return inputs.push({
        model: '',
      });
    },
    deletedCodeInput(inputs, index2) {
      this.$swal({
        title: '¿Eliminar código?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(resp => {
        if (resp.isConfirmed) {
          inputs.splice(index2, 1);
        }
      });
    },

    copyToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('No se pudo copiar al portapapeles: ', err);
      }

      document.body.removeChild(textArea);
    },

    copyExample(element) {
      this.copyToClipboard(element.placeholder);
      this.$swal({
        toast: true,
        icon: 'success',
        title: '¡Ejemplo copiado al portapapeles!',
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
    },
  },
  watch: {
    selectedIntent() {
      this.initialize();
    },
    elements: {
      handler() {
        this.$emit('onChangeNode', this.elements);
      },
      deep: true,
    },
  },
};
</script>
